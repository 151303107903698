export const environment = {
  production: true,
  redirectUri: 'https://business.development.cafler.com/',
  B2CUri: 'https://products.development.cafler.com',
  apiGatewayUri: 'https://api.development.cafler.com/dev/identity',
  azureActiveDirectoryB2C: {
    instance: 'identity.cafler.com',
    tenant: '38e0a06f-5e98-4f79-b789-9ac3f3c7638b',
    policyName: 'B2C_1_B2bDashboard_Development',
    clientId: '8910a84a-9630-4dbf-b74c-62e0914d24dd'
  },
  sentryEnvironment: "development",
  assetsURL: 'caflerweb.azureedge.net',
};
