
<article class="product-box"   
[title]="settings.alt | translate"
(click)="selectProduct()">
  <div class="header">
    <div class="header__container">
      <div class="header__content">
        <h2 class="header__content__title" [innerHTML]="settings.title | translate"></h2>
        <img class="vertical-icon" [src]="settings.productIcon"> <!-- imagen del icon-->
      </div>
    </div>
  </div> 
  <div class= "product"> 
    <div class="product__description">
      <div class="product-description" [innerHTML]="settings.description | translate"></div>
      <div *ngIf="settings.providerSlogan || settings.providerIcon; else blank_space" class="providers-container">
        <p class="subtitle-3 providers-label" *ngIf="settings.providerSlogan">{{settings.providerSlogan | translate}}</p>
        <caf-icon *ngIf="settings.providerIcon" [settings]="settings.providerIcon"></caf-icon>
      </div>
      <ng-template #blank_space class><span class="blank_space"></span></ng-template>
      </div>
  </div>
</article>
