import { environment } from 'src/environments/environment';
import { ProductCard } from '../types';

export const products: ProductCard[] = [
  {
    title: 'vertical.transfer.title',
    description: 'vertical.transfer.description',
    alt: 'vertical.transfer.title',
    productIcon: `https://${environment.assetsURL}/images/icons/pin-3D.svg`,
    countriesAvailable: ['esp', 'fra', 'gbr'],
    id: 'Transfer',
  },
  {
    title: 'vertical.medium-long-distance.title',
    description: 'vertical.medium-long-distance.description',
    alt: 'vertical.medium-long-distance.title',
    productIcon: `https://${environment.assetsURL}/images/icons/medium-long-distance-icon.svg`,
    countriesAvailable: ['esp', 'fra', 'gbr'],
    id: 'TowTruckTransfer', // TODO: wrong id
  },
  {
    title: 'vertical.itv-spain.title',
    description: 'vertical.itv-spain.description',
    alt: 'vertical.itv-spain.title',
    productIcon: `https://${environment.assetsURL}/images/icons/cafler-logo-itv-spain.svg`,
    countriesAvailable: ['esp', 'fra', 'gbr'],
    id: 'TechnicalInspection',
  },
  {
    title: 'vertical.wash.title',
    description: 'vertical.wash.description',
    alt: 'vertical.wash.title',
    productIcon: `https://${environment.assetsURL}/images/icons/wash-3D.svg`,
    countriesAvailable: ['esp', 'fra', 'gbr'],
    id: 'VehicleWash',
  },
  {
    title: 'vertical.maintenance.title',
    description: 'vertical.maintenance.description',
    alt: 'vertical.maintenance.title',
    productIcon: `https://${environment.assetsURL}/images/icons/maintenance-pack-3D.svg`,
    countriesAvailable: ['esp'],
    id: 'Revision',
  },
  {
    title: 'vertical.pre-mot.title',
    description: 'vertical.pre-mot.description',
    alt: 'vertical.pre-mot.title',
    productIcon: `https://${environment.assetsURL}/images/icons/file-3D.svg`,
    countriesAvailable: ['esp'],
    id: 'Pre-mot',
  },
  {
    title: 'vertical.valet.title',
    description: 'vertical.valet.description',
    alt: 'vertical.valet.title',
    productIcon: `https://${environment.assetsURL}/images/icons/drivers-hat-3D.svg`,
    countriesAvailable: ['esp'],
    id: 'Valet',
  },
  {
    title: 'vertical.formalities.title',
    description: 'vertical.formalities.description',
    alt: 'vertical.formalities.title',
    productIcon: `https://${environment.assetsURL}/images/icons/formalities.svg`,
    countriesAvailable: ['esp'],
    id: 'Formalities',
  },
  {
    title: 'vertical.parking.title',
    description: 'vertical.parking.description',
    alt: 'vertical.parking.title',
    productIcon: `https://${environment.assetsURL}/images/icons/parking.svg`,
    countriesAvailable: ['esp'],
    id: 'Parking',
  },
  {
    title: 'vertical.refuel.title',
    description: 'vertical.refuel.description',
    alt: 'vertical.refuel.title',
    productIcon: `https://${environment.assetsURL}/images/icons/refuel.svg`,
    countriesAvailable: ['esp', 'fra', 'gbr'],
    id: 'Fuel',
  },
  {
    title: 'vertical.tow-truck.title',
    description: 'vertical.tow-truck.description',
    alt: 'vertical.tow-truck.title',
    productIcon: `https://${environment.assetsURL}/images/icons/tow-truck.svg`,
    countriesAvailable: ['esp', 'fra', 'gbr'],
    id: 'tow-truck',
  },
  {
    title: 'vertical.all-you-need.title',
    description: 'vertical.all-you-need.description',
    alt: 'vertical.all-you-need.title',
    productIcon: `https://${environment.assetsURL}/images/icons/magic-hat-3D.svg`,
    countriesAvailable: ['esp', 'fra', 'gbr'],
    id: 'all-you-need',
  },
];
