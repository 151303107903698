import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { ProfileData } from 'src/app/types';
import { Subject } from 'rxjs';
import { NEWS_URLS, USER_MANUAL_URLS } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  menuState = {
    isMyServicesSelected: false,
    isMyAccountSelected: false,
    isHelpSelected: false
  }
  profilePicture = '';
  displayName = '';

  URLS = {
    NEWS:'',
    USER_MANUAL: ''
  }

  imgULS = {
    crossAddIcon: `https://${environment.assetsURL}/images/icons/cross-add-icon.svg`,
    listIcon: `https://${environment.assetsURL}/images/icons/list-icon.svg`,
    personIcon: `https://${environment.assetsURL}/images/icons/person-icon.svg`,
    logoCaflerB2B: `https://${environment.assetsURL}/images/logos/Logo_cafler_b2b.svg`,
  }

  constructor(
    private router: Router,
    private authService: MsalService,
    private dataService: DataService
  ) { 

    this.dataService.getProfileData().pipe(takeUntil(this._destroying$)).subscribe((res: ProfileData) => {
      this.URLS.NEWS = NEWS_URLS[res.accountRegionIsoCode];
      this.URLS.USER_MANUAL = USER_MANUAL_URLS[res.accountRegionIsoCode];
    });

  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if(event.url.includes('account')){
          this.menuState.isMyAccountSelected = true;
          this.menuState.isMyServicesSelected = false;
          this.menuState.isHelpSelected = false;
        }
        if(event.url.includes('my-services')){
          this.menuState.isMyAccountSelected = false;
          this.menuState.isMyServicesSelected = true;
          this.menuState.isHelpSelected = false;
        }
        if(event.url.includes('help')){
          this.menuState.isMyAccountSelected = false;
          this.menuState.isMyServicesSelected = false;
          this.menuState.isHelpSelected = true;
        }
        
      });

      this.getProfilePicture();
      this.dataService.profilePictureUpdated.subscribe(() => {
        this.getProfilePicture();
      });
  }


  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  getProfilePicture(){
    this.dataService.getProfileData().subscribe((data: any) => {
      this.profilePicture = data.profilePhotoUrl;
      this.displayName = data.displayName;
    });
  }
  
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
