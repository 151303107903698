import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { products } from '../../products';
import { ProductCard, ProfileData } from '../../../types';

@Component({
  selector: 'service-selector',
  templateUrl: './service-selector.component.html',
  styleUrls: ['./service-selector.component.scss'],
})
export class ServiceSelectorComponent implements OnInit {
  private profileData: ProfileData;

  productSettings: ProductCard[] = [];

  constructor(private router: Router, private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.getProfileData().subscribe((data) => {
      if (data) {
        this.profileData = data;
        this.getProductSettings();
        console.log(this.productSettings);
      }
    });
  }

  getProductSettings() {
    products.map((product: ProductCard) => {
      console.log(this.profileData.accountRegionIsoCode);
      if (
        product.countriesAvailable.includes(
          this.profileData.accountRegionIsoCode
        )
      ) {
        this.productSettings.push(product);
      }
    });
  }

  pickProduct(verticalId: string) {
    if (verticalId === 'Parking') {
      // chequear si es francia o españa
      if (this.profileData.accountRegionIsoCode === 'fra') {
        window.open(
          'https://www.onepark.fr/?utm_source=cafler&utm_medium=Affiliates',
          '_blank'
        );
      }
      if (this.profileData.accountRegionIsoCode === 'esp') {
        window.open(
          'https://www.onepark.co/es?utm_medium=Affiliates&utm_source=cafler',
          '_blank'
        );
      }
      return;
    }
    this.router.navigate(['/service', verticalId]);
  }
}
