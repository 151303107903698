import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TextFormatterService } from './text-formatter.service';
import { profile } from 'console';
import { ProfileData } from '../types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  profileData: any;
  scheduleData: any;
  billingData: any;

  profilePictureUpdated = new BehaviorSubject<boolean>(false);

  alreadyLoading = false;

  constructor(
    private request: RequestService,
    private textFormatterService: TextFormatterService
  ) {

      // this.refreshData().subscribe((data: any) => { 
      //   this.profileData = data[0];
      //   this.scheduleData = data[1];
      // });

    }


    setSilentToken(token: any) {
      this.request.setSilentToken(token);
    }


    refreshProfileData(): Observable<any>{
      return this.request.getProfileData().pipe(
        map((res) => {
        const resData = this.textFormatterService.convertObjectToCamelCase(
          res,
          this.textFormatterService.pascalCaseToCamelCase,
          );

        // made to prevent from migration errors
        if(resData.userLanguage === null){
          resData.userLanguage = 'es-ES';
          if(navigator.language.includes('es')){
            resData.userLanguage = 'es-ES';
          }
          if(navigator.language.includes('en')){
            resData.userLanguage = 'en-GB';
          }
          if(navigator.language.includes('fr')){
            resData.userLanguage = 'fr-FR';
          }

        }
        if(resData.profilePhotoUrl === null ||resData.profilePhotoUrl === ''){
          resData.profilePhotoUrl = `https://${environment.assetsURL}/images/logos/default-profile-picture.svg`;
        }
        return resData;
        }),
        );
    }

    refreshScheduleData(): Observable<any>{
      return this.request.getScheduleSlots().pipe(
        map((res) => {
          const test = this.textFormatterService.convertObjectToCamelCase(
            res,
            this.textFormatterService.pascalCaseToCamelCase,
            );
            
            return test.businessScheduleComposition;
          }),
        );
    }

    refreshBillingData(): Observable<any>{
      return this.request.getBillingData().pipe(
        map((res) => {
          return this.textFormatterService.convertObjectToCamelCase(
            res,
            this.textFormatterService.pascalCaseToCamelCase,
          );
        }),
      );
    }
    

    getProfileData(): Observable<ProfileData>{
      const token = this.request.getSilentToken();
      const todaysDate = new Date();
      if (this.profileData && token && token.expiresOn > todaysDate) {
        return of(this.profileData);
      }
      else {
        return this.refreshProfileData().pipe(
          map((res: any) => {
            if (res) {
              this.profileData = res;
              return this.profileData; 
            }
          })
        )
        }
    }

    getScheduleData(){
      const token = this.request.getSilentToken();
      const todaysDate = new Date();
      if (this.scheduleData && token && token.expiresOn > todaysDate) {
        return of(this.scheduleData);
      }
      else {
        return this.refreshScheduleData().pipe(
          map((res: any) => {
            if (res) {
              this.scheduleData = res;
              return this.scheduleData; 
            }
          })
        )
        }
    }

    getBillingData(){
      const token = this.request.getSilentToken();
      const todaysDate = new Date();
      if (this.billingData && token && token.expiresOn > todaysDate) {
        return of(this.billingData);
      }
      else {
        return this.refreshBillingData().pipe(
          map((res: any) => {
            if (res) {
              this.billingData = res;
              return this.billingData; 
            }
          })
        )
        }
    }

    changeProfilePicture(newPicture: FormData): Observable<any>{
      return this.request.uploadProfilePicture(newPicture).pipe(
        tap((res) => {
          this.profileData.profilePhotoUrl = res;
          this.profilePictureUpdated.next(true);
        })
      )
    }
    
    sendHelpRequest(helpRequest: FormData){
      return this.request.sendHelpRequest(helpRequest);
    }

    updateProfileData(profileData: any){
      const formattedProfileData = this.textFormatterService.convertObjectToCamelCase(
        profileData,
        this.textFormatterService.camelCaseToPascalCase,
      )
      return this.request.updateProfileData(formattedProfileData);
    }

    updateBillingData(billingData: any){
      const formattedBillingData = this.textFormatterService.convertObjectToCamelCase(
        billingData,
        this.textFormatterService.camelCaseToPascalCase,
      )
      return this.request.updateBillingData(formattedBillingData);
    }

    updatePassword(password: any){
      const formattedPasswordChangeData = this.textFormatterService.convertObjectToCamelCase(
        password,
        this.textFormatterService.camelCaseToPascalCase,
      )
      return this.request.updatePassword(formattedPasswordChangeData);
    }

    updateScheduleData(scheduleData: any){
      const formattedScheduleData = this.textFormatterService.convertObjectToCamelCase(
        scheduleData,
        this.textFormatterService.camelCaseToPascalCase,
      )
      return this.request.updateScheduleData({'BusinessScheduleComposition' : formattedScheduleData});
    }
}
