import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  token: any;

  constructor(private http: HttpClient) {}

  public getSilentToken() {
    return this.token;
  }

  public setSilentToken(token: string) {
    this.token = token;
  }

  // TODO: CHECK IF TOKEN IS EXPIRED AND REFRESH IT. IF IT DOESNT HAS AN ACCOUNT THROW ERROR
  getAuthorizationHeaders() {
    //check if token is expired and refresh it

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.getSilentToken().accessToken,
    });
    return headers;
  }

  getProfileData(): Observable<any> {
    return this.http.get(`${environment.apiGatewayUri}/user/me`, {
      headers: this.getAuthorizationHeaders(),
    });
  }

  sendHelpRequest(data: FormData): Observable<any> {
    return this.http.post(`${environment.apiGatewayUri}/user/me/help`, data, {
      headers: this.getAuthorizationHeaders(),
    });
  }

  getScheduleSlots(): Observable<any> {
    return this.http.get(
      `${environment.apiGatewayUri}/user/me/business/schedule`,
      { headers: this.getAuthorizationHeaders() }
    );
  }

  getBillingData(): Observable<any> {
    return this.http.get(
      `${environment.apiGatewayUri}/user/me/business/billing`,
      { headers: this.getAuthorizationHeaders() }
    );
  }

  uploadProfilePicture(data: FormData): Observable<any> {
    return this.http.post(
      `${environment.apiGatewayUri}/user/me/profile-picture`,
      data,
      { headers: this.getAuthorizationHeaders() }
    );
  }

  updateProfileData(data: any) {
    return this.http.put(`${environment.apiGatewayUri}/user/me`, data, {
      headers: this.getAuthorizationHeaders(),
    });
  }

  updateBillingData(data: any) {
    return this.http.put(
      `${environment.apiGatewayUri}/user/me/business/billing`,
      data,
      { headers: this.getAuthorizationHeaders() }
    );
  }

  updatePassword(data: any) {
    return this.http.patch(
      `${environment.apiGatewayUri}/user/me/security/password`,
      data,
      { headers: this.getAuthorizationHeaders() }
    );
  }

  updateScheduleData(data: any) {
    return this.http.put(
      `${environment.apiGatewayUri}/user/me/business/schedule`,
      data,
      { headers: this.getAuthorizationHeaders() }
    );
  }
}
