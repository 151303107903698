import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ServiceSelectorComponent } from './pages/service-selector-page/service-selector.component';
import { ProductCardComponent } from './components/product-card/product-card.component';

@NgModule({
  declarations: [ServiceSelectorComponent, ProductCardComponent],
  imports: [SharedModule],
})
export class ServiceSelectorModule {}
