import { NgModule } from '@angular/core';
import { Routes, RouterModule, InitialNavigation } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { FailedComponent } from './failed/failed.component';
import { IframeContainerComponent } from './iframe-container/iframe-container.component';

import { ServiceSelectorComponent } from './service-selector/pages/service-selector-page/service-selector.component';

const routes: Routes = [
  {
    path: 'service',
    component: ServiceSelectorComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./help-page/help-page.module').then((m) => m.HelpPageModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'service/:verticalId',
    component: IframeContainerComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'my-services',
    component: IframeContainerComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    redirectTo: 'my-services',
    pathMatch: 'full',
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
];

const initialNavigation: InitialNavigation =
  !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? 'enabledNonBlocking'
    : 'disabled';

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
