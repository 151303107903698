<aside class="sidebar-container">
  <header class="first-section">
    <img class="profile-picture" [src]="profilePicture" />
    <h3 class="account-name">{{ displayName }}</h3>
  </header>
  <div class="sidebar-body">
    <div class="options-container">
      <nav class="nav-menu">
        <ul>
          <li class="button-container">
            <a class="button new-service link" [routerLink]="['/service/']">
              <img
                class="new-service-button-icon"
                src="https://stgcafassetsweb.blob.core.windows.net/images/icons/cross-add-icon.svg"
              />
              {{ "new-service.button" | translate }}
            </a>
          </li>
          <li>
            <a
              class="secondary-button link"
              [ngClass]="{ 'selected-button': menuState.isMyServicesSelected }"
              [routerLink]="['/my-services/']"
            >
              <img
                class="button-icon"
                src="https://stgcafassetsweb.blob.core.windows.net/images/icons/list-icon.svg"
              />
              {{ "my-services.button" | translate }}
            </a>
          </li>
          <li>
            <a
              class="secondary-button link"
              [ngClass]="{ 'selected-button': menuState.isMyAccountSelected }"
              [routerLink]="['/account/']"
            >
              <img
                class="button-icon"
                src="https://stgcafassetsweb.blob.core.windows.net/images/icons/person-icon.svg"
              />
              {{ "my-account.button" | translate }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div>
      <nav class="nav-menu">
        <ul>
          <li>
            <a
              class="secondary-button link"
              [routerLink]="['/help']"
              [ngClass]="{ 'selected-button': menuState.isHelpSelected }"
              >{{ "cafler.aside.nav.help" | translate }}</a
            >
          </li>
          <li>
            <a
              class="secondary-button link"
              [href]="this.URLS.NEWS"
              target="_blank"
              >{{ "cafler.aside.nav.news" | translate }}</a
            >
          </li>
          <li>
            <a
              class="secondary-button link"
              [href]="this.URLS.USER_MANUAL"
              target="_blank"
              >{{ "cafler.aside.nav.user-manual" | translate }}</a
            >
          </li>
        </ul>
      </nav>
      <div class="line"></div>
      <!-- <div class="empty-space"></div> -->
      <p class="logout-button" (click)="logout()">
        {{ "close-session.button" | translate }}
      </p>
    </div>
  </div>
  <footer class="footer">
    <img
      class="b2b-logo"
      src="https://stgcafassetsweb.blob.core.windows.net/images/logos/Logo_cafler_b2b.svg"
    />
  </footer>
</aside>
