import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { DomSanitizer} from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { DataService } from '../services/data.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-iframe-container',
  templateUrl: './iframe-container.component.html',
  styleUrls: ['./iframe-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeContainerComponent implements OnInit {

  currentPage: string = 'iframe-container';
  url = environment.B2CUri;
  iframeWidth!: any;
  iframeHeight!: any;
  productType!: string;

  alreadyLoaded = false;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private dataService: DataService
    ) { }

  ngOnInit(): void {
    this.iframeWidth = `${window.innerWidth - 265}px`;
    this.iframeHeight = `${window.innerHeight}px`;
    this.currentPage = this.router.url.split('/')[1];
    this.productType = this.router.url.split("/")[this.router.url.split("/").length-1]
    if(this.currentPage.includes('my-services')){
      this.url = this.url + '/my-services'
      this.productType = 'MyServices';
    }
    if(this.currentPage.includes('new-service')){

    }

    this.dataService.getProfileData()
    .pipe(first())
    .subscribe(
      (res: any) => {
        if(res && !this.alreadyLoaded){
          const userDataParams = JSON.stringify(this.getJson(res));
          let userDataParamsSanitized = encodeURIComponent(userDataParams)
          this.url = this.url + '?b2bParams=' + userDataParamsSanitized;
          this.alreadyLoaded = true;
        }

      }
    )
  }



  // if(page === VerticalsTypeEnum.PARKING ){
  //   switch(this.currentCountry.RegionIsoCode){
  //     case 'esp':
  //       window.location.href = 'https://www.onepark.co/es?utm_medium=Affiliates&utm_source=cafler';
  //       break;
  //     case 'fra':
  //       window.location.href = 'https://www.onepark.fr/?utm_source=cafler&utm_medium=Affiliates';
  //       break;
  //     default:
  //     break;
  //   }
  //   return;
  // }

  cleanUrl(oldUrl){
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldUrl);
  }


  getJson(userData){
    return {
      "userData": {
        "garageName": userData.displayName,
        "name": userData.firstName,
        "surname": userData.lastName,
        "phoneNumber": userData.phone,
        "email": userData.email,
        // "CIForDNI": "111111111-X",
        // "billingAddress": "C/SARAGOSSA, 46 TALLER DE MOTOS",
        // "garageWorkingHours": {
        //   "start": "0001-01-01T00:14:44.000Z",
        //   "end": "0001-01-01T00:14:44.000Z"
        // },
        "businessAddress": userData.defaultAddress,
        "businessAddressLatitude": userData.defaultAddressLatitude,
        "businessAddressLongitude": userData.defaultAddressLongitude
      },
      "userZone": {
        "zoneName": userData.deprecatedZoneName
      },
      "userLang": userData.userLanguage,
      "userId": userData.userId,
      "serviceType": this.productType
    }
  }

}
