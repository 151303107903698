<div *ngIf="appIsReady; else loading" class="app-container">
  <app-sidebar class="menu-container"></app-sidebar>
  <main class="main-container">
    <router-outlet class="content-container"></router-outlet>
    <ng-container #toastContainer></ng-container>
  </main>
</div>
<ng-template #loading>
  <main class="full-screen center">
    <div>
      <img
        src="assets/logo-loading.gif"
        alt="loading..."
        height="60.19px"
        width="107px"
      />
    </div>
  </main>
</ng-template>
