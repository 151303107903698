<section [ngSwitch]="currentPage">
  <iframe
    *ngSwitchCase="'service'"
    [width]="iframeWidth"
    [height]="iframeHeight"
    [src]="cleanUrl(url)"
  ></iframe>
  <iframe
    *ngSwitchCase="'my-services'"
    [width]="iframeWidth"
    [height]="iframeHeight"
    [src]="cleanUrl(url)"
  ></iframe>
  <div *ngSwitchDefault>{{ "unexpected-error.message" | translate }}</div>
</section>
