import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { DataService } from './services/data.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private msalService: MsalService,
    private dataService: DataService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Renovar el token de acceso
          return this.msalService.acquireTokenSilent({ 
            scopes: [
              'https://identity.cafler.com/my-identity/Business.Identity',
              'https://identity.cafler.com/my-identity/Business.Services'
            ]
           }).pipe(
            switchMap((response) => {
              this.dataService.setSilentToken(response);
              // Agregar el token renovado a la cabecera de la solicitud
              const authorizedRequest = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${response.accessToken}`
                }
              });
              // Reintentar la solicitud original con el token renovado
              return next.handle(authorizedRequest);
            }),
            catchError((error) => {
              // Manejar el error de renovación del token
              return throwError(error);
            })
          );
        }
        // Si no es un error 401, propagar el error original
        return throwError(error);
      })
    );
  }
}
