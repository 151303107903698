import { environment } from "src/environments/environment";
import { CountryInformation } from "./types";

export const CONTACT_PHONE_NUMBERS: CountryInformation = {
    esp: "+34 900 649 071",
    fra: "+33 1 84 88 33 56",
    gbr: "+44 2 045 797 281",
} 

export const FAQ_URLS: CountryInformation = {
    esp: "https://autocafler.notion.site/Nuestras-FAQ-s-Cafler-Empresas-08fb251f2b144d4eb47bb60bd5ed9cc4",
    fra: "https://autocafler.notion.site/Nos-FAQ-s-Cafler-Entreprise-fc5a397be40344ae8fad44293e9a842d",
    gbr: "https://autocafler.notion.site/Our-FAQ-s-Cafler-Enterprise-b6409d49fce242159803887ae48b8a9f?pvs=4",
}

export const NEWS_URLS: CountryInformation = {
    esp: "https://autocafler.notion.site/Comunicaciones-Espa-a-dea3da7f5ba641c79ad696ee7bf417de",
    fra: "https://autocafler.notion.site/Communications-France-7ba51a7949a646d8a8fa943057e6bf18",
    gbr: "https://autocafler.notion.site/Communications-UK-44487b7cad86410481faf180ecac4bed",
}

export const USER_MANUAL_URLS: CountryInformation = {
    esp: `https://${environment.assetsURL}/documents/user-manual-es.pdf`,
    fra: `https://${environment.assetsURL}/documents/user-manual-fr.pdf`,
    gbr: `https://${environment.assetsURL}/documents/user-manual-uk.pdf`,
}


export const capMailAttachmentSize = 25 * 1024 * 1024; // 25MB