import { Injectable } from '@angular/core';

const pascalCasePattern = new RegExp('^([A-Z])([a-z]+)');

@Injectable({
  providedIn: 'root'
})
export class TextFormatterService {

  constructor() { }

  pascalCaseToCamelCase(propname: string): string {
    if (pascalCasePattern.test(propname)) {
      return propname.charAt(0).toLowerCase() + propname.slice(1);
    } else {
      return propname;
    }
  }

  camelCaseToPascalCase(propname: string): any {
    return propname.charAt(0).toUpperCase() + propname.slice(1);

  }
  convertObjectToCamelCase(obj: any, converterFn: any): any {
    let r,
      value,
      t = Object.prototype.toString.apply(obj);
    if (t == '[object Object]') {
      r = {};
      for (let propname in obj) {
        value = obj[propname];
        // @ts-ignore
        r[converterFn(propname)] = this.convertObjectToCamelCase(value, converterFn);
      }
      return r;
    } else if (t == '[object Array]') {
      r = [];
      for (var i = 0, L = obj.length; i < L; ++i) {
        value = obj[i];
        r[i] = this.convertObjectToCamelCase(value, converterFn);
      }
      return r;
    }
    return obj;
  }
}
