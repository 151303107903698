import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaflerButtonComponent } from './components/UI/cafler-button/cafler-button.component';
import { CaflerInputComponent } from './components/UI/cafler-input/cafler-input.component';
import { CaflerSelectComponent } from './components/UI/cafler-select/cafler-select.component';
import { CaflerToggleComponent } from './components/UI/cafler-toggle/cafler-toggle.component';
import { FileUploadComponent } from './components/UI/file-upload/file-upload.component';
import { PasswordInputComponent } from './components/UI/password-input/password-input.component';
import { PhoneInputComponent } from './components/UI/phone-input/phone-input.component';
import { ToastComponent } from './components/UI/toast/toast.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    CaflerButtonComponent,
    CaflerInputComponent,
    CaflerSelectComponent,
    CaflerToggleComponent,
    FileUploadComponent,
    PasswordInputComponent,
    PhoneInputComponent,
    ToastComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'es-ES',
    }),
  ],
  exports: [
    CommonModule,
    CaflerButtonComponent,
    CaflerInputComponent,
    CaflerSelectComponent,
    CaflerToggleComponent,
    FileUploadComponent,
    PasswordInputComponent,
    PhoneInputComponent,
    ToastComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class SharedModule {}
