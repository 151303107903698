<div class="page-container">
  <div class="navbar">
    <h2 class="page-title">{{ "new-service.title" | translate }}</h2>
    <div class="header-container">
      <div class="separator"></div>
    </div>
  </div>
  <div class="content">
    <div class="title-container">
      {{ "new-service-selector.title" | translate }}
    </div>
    <div class="vertical-cards-container">
      <product-card
        class="card"
        [class]="'card' + (i + 1)"
        *ngFor="let product of productSettings; let i = index"
        [settings]="product"
        (productSelected)="pickProduct($event)"
      >
      </product-card>
    </div>
  </div>
</div>
